<template>

<div class="compare">

	<div class="compare-left" v-if="window.is.mobile && index > 0" v-on:click="onPreviousClick" />
	<div class="compare-right" v-if="window.is.mobile && index < 4" v-on:click="onNextClick" />

	<div class="compare-items">

		<div class="compare-item" v-for="(column, coldex) in columns" :key="column" :class="{'is-active': columns[index] === column, 'is-left': index > coldex, 'is-right': index < coldex }">

			<div class="compare-item-head" :class="{['is-' + column]: true}" v-html="titles[column]" />

			<div class="compare-item-content" v-html="data[column].text" />

			<div v-on:click="onVideoExpand(column)" class="compare-item-foot" v-if="data[column].video || data[column].more" :class="{'is-video': data[column].video, 'is-more': data[column].more}">

				<span></span>

				<div v-if="data[column].video && is.expanded !== column">video</div>
				<div v-if="data[column].video && is.expanded === column">close</div>
				<router-link v-if="data[column].more" :to="{name: 'Recommendation.Compare.More', params: { more: data[column].more }}">more info</router-link>

			</div>

		</div>

	</div>

	<div class="compare-item-videos" v-if="is.expanded">

		<app-button class="compare-item-videos-item" v-on:click="onVideoClick(product)" v-for="(product, index) in products" :key="index" :text="product.name" />

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {
			is: {
				expanded: false
			},
			index: 0,
			columns: ['bai', 'dpi', 'pmdi', 'pmdis', 'smi'],
			typeIndex: {
				bai: 4,
				dpi: 1,
				pmdi: 2,
				pmdis: 3,
				smi: 5
			},
			titles: {
				bai: 'BAI',
				dpi: 'DPI',
				pmdi: 'pMDI',
				pmdis: 'pMDI <small>with spacer</small>',
				smi: 'SMI'
			}
		}

	},

	computed: {

		products: function() {

			if (!this.is.expanded) return []

			return this.$_.where(this.$store.getters['data'].inhalers.products, {
				type: this.typeIndex[this.is.expanded]
			})

		}

	},

	methods: {

		onVideoClick: function(product) {

			this.$router.push({
				name: 'Recommendation.Video',
				params: {
					product: product,
					type: this.$_.findWhere(this.$store.getters['types'], {
						id: product.type
					}),
					return: 'Recommendation.Compare'
				}
			})

		},

		onVideoExpand: function(column) {

			if (this.is.expanded === column) {

				this.is.expanded = false

			} else {

				if (this.data[column].video) {

					this.is.expanded = column

				}

			}

		},

		onPreviousClick: function() {

			this.is.expanded = false
			this.index--
			
		},

		onNextClick: function() {

			this.is.expanded = false
			this.index++
			
		}

	}

}

</script>

<style scoped>

.compare-left,
.compare-right {
	width: 32px;
	height: 32px;
	position: absolute;
	background-repeat: no-repeat;
	cursor: pointer;
	z-index: 2;
	top: 0px;
}

.compare-left {
	left: 0px;
	background-position: 5px 50%;
	background-image: url(~@/assets/chevron.left.svg);
}

.compare-right {
	right: 0px;
	background-position: 15px 50%;
	background-image: url(~@/assets/chevron.right.svg);
}

.compare-items {
	display: flex;
	justify-content: space-between;
}

.compare-item {
	width: calc(20% - 20px);
	display: flex;
	flex-direction: column;
}

.is-mobile .compare-item {
	width: 100%;
	padding: 0px 32px;
	flex-shrink: 0;
	position: absolute;
	opacity: 0;
	transition: all 300ms ease-in-out;
}

.is-mobile .compare-item.is-left {
	left: -100%;
}

.is-mobile .compare-item.is-right {
	left: 100%;
}

.is-mobile .compare-item.is-active {
	opacity: 1;
	left: 0%;
	position: relative;
}

.compare-item-head {
	border-radius: 16px;
	height: 32px;
	text-align: center;
	color: #fff;
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
}
.compare-item-head >>> small {
	font-size: 18px;
	font-weight: 300;
}

.compare-item-head.is-bai {
	background-color: #FF9300;
}

.compare-item-head.is-dpi {
	background-color: #58C145;
}

.compare-item-head.is-pmdi {
	background-color: #35B3CB;
}

.compare-item-head.is-pmdis {
	background-color: #2671A8;
}

.compare-item-head.is-smi {
	background-color: #8348AF;
}

.compare-item-content {
	padding: 20px 0px 0px 0px;
	text-align: center;
	flex-grow: 1;
}

.compare-item-content:after {
	display: block;
	position: absolute;
	content: '';
	top: 20px;
	bottom: 20px;
	width: 1px;
	background-color: #f5f5f5;
	right: -5px;
}

.compare-item:last-child .compare-item-content:after {
	display: none;
}

.compare-item-content >>> p {
	font-size: 16px;
	line-height: 19px;
	color: #343434;
	margin-bottom: 20px;
}

.compare-item-content >>> p:last-child {
	margin-bottom: 0px;
}

.compare-item-content >>> img {
	max-width: 100%!important;
	height: auto!important;
}

.compare-item-foot {
	text-align: center;
	font-size: 14px;
	letter-spacing: 1.4px;
	color: #2A273E;
	text-transform: uppercase;
	font-weight: 400;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.compare-item-foot span {
	background-color: #2A273E;
	display: inline-block;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin-right: 10px;
}

.compare-item-foot.is-video span {
	background-image: url(~@/assets/play.svg);
	cursor: pointer;
}

.compare-item-foot.is-more span {
	background-image: url(~@/assets/info.svg);
}

.compare-item-foot div {
	cursor: pointer;
}

.compare-item-videos {
	display: flex;
	margin-top: 30px;
	justify-content: center;
	flex-wrap: wrap;
}

.compare-item-videos-item {
	width: auto;
	margin: 10px;
}

.is-mobile .compare-item-videos-item {
	width: calc(100% - 60px);
	margin: 0px 0px 10px 0px;
}

</style>
